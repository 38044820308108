<template>
  <b-container>
    <b-row class="mb-3">
      <b-col>
        <h1 v-if="showTitle">{{ $t("orders.title") }}</h1>
        <b-row v-else-if="isSeller" align-v="end">
          <b-col cols="auto">
            <b-form-checkbox
              v-model="selectAllVisibleOrderCheckbox"
              :value="true"
              :unchecked-value="false"
              @change="toggleSelectedOrders"
            >
              {{ $t("orders.actions.select-all") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="auto">
            <b-form-select
              id="order-status"
              :disabled="selectedOrders.length === 0"
              v-model="orderStatus"
              :options="statusOptions"
              @change="updateStatus"
              size="sm"
            ></b-form-select>
          </b-col>
          <b-col cols="auto">
            <b-button @click="exportPdfs" :disabled="selectedOrders.length === 0" size="sm">
              <b-spinner small type="grow" v-if="exportOrdersPdfLoading" class="align-middle mr-1"></b-spinner>
              <b-icon-download v-else class="mr-2"></b-icon-download>
              {{ $t("orders.pdf") }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="text-right">
        <b-button v-if="isSeller" class="mr-4" @click="exportOrders">
          <b-spinner small type="grow" v-if="exportOrdersLoading" class="align-middle mr-1"></b-spinner>
          {{ $t("orders.export") }}
        </b-button>
        <b-form-select
          id="order-filter-status"
          class="mr-3"
          v-model="filter.status"
          :options="statusOptions"
        ></b-form-select>
        <b-form-input
          type="search"
          v-model="filter.search"
          debounce="250"
          :placeholder="$t('common.search')"
          class="d-inline w-auto mr-3"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-table
      ref="orders-table"
      responsive
      :items="orders"
      :fields="columns"
      :per-page="limit"
      :filter="filter"
      :current-page="currentPage"
      :busy="isLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :selectable="isSeller"
      @row-selected="orderSelected"
    >
      <template #cell(items)="data">
        <ul class="list-unstyled">
          <li v-for="item in data.value.filter(item => item.type === 'product')" :key="`item-list-${item.id}`">
            {{ getItemName(item, data) }}
          </li>
        </ul>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          :to="{ name: isSeller ? 'MyShop/Order' : 'User/Order', params: { id: row.item.id, liveId: liveId } }"
          class="mr-1"
        >
          <b-icon-eye></b-icon-eye>
        </b-button>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col xl="1" offset-xl="7" offset-lg="5" md="2" offset-md="4" sm="2" cols="3">
        <b-form-select id="per-page-select" v-model="limit" :options="[5, 10, 20, 50, 100]" size="sm"></b-form-select>
      </b-col>
      <b-col xl="4" lg="5" md="6" sm="10" cols="9">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="limit"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import helper from "@/utils/helper";
import { mapGetters } from "vuex";
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Orders",
  props: {
    listApi: Function,
    liveId: Number,
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      limit: 10,
      filter: {
        search: null,
        status: null,
      },
      currentPage: 1,
      total: 0,
      isLoading: false,
      exportOrdersLoading: false,
      statusOptions: [
        { value: null, text: this.$t("orders.columns.status") },
        { value: "waiting_payment_bank_wire", text: this.$t("orders.status.waiting_payment_bank_wire") },
        { value: "waiting_payment_shop", text: this.$t("orders.status.waiting_payment_shop") },
        { value: "paid", text: this.$t("orders.status.paid") },
        { value: "paid_with_voucher", text: this.$t("orders.status.paid_with_voucher") },
        { value: "package_in_preparation", text: this.$t("orders.status.package_in_preparation") },
        { value: "available_in_shop", text: this.$t("orders.status.available_in_shop") },
        { value: "shipped", text: this.$t("orders.status.shipped") },
        { value: "cancelled", text: this.$t("orders.status.cancelled") },
        { value: "closed", text: this.$t("orders.status.closed") },
        { value: "pos_sale", text: this.$t("orders.status.pos_sale") },
      ],
      restoring: false,
      selectAllVisibleOrderCheckbox: false,
      selectedOrders: [],
      exportOrdersPdfLoading: false,
      orderStatus: null,
    };
  },
  computed: {
    ...mapGetters("authentication", ["isSeller"]),
    columns() {
      if (this.isSeller) {
        return [
          {
            key: "user",
            label: this.$t("orders.columns.user-name"),
            formatter: value => value?.name,
            sortable: true,
          },
          {
            key: "live",
            label: this.$t("orders.columns.live"),
            formatter: value => (value ? helper.formatDate(value.expectedStartTime) : ""),
            sortable: true,
          },
          {
            key: "items",
            label: this.$t("orders.columns.items-count"),
          },
          {
            key: "date",
            label: this.$t("orders.columns.date"),
            formatter: value => (value ? helper.formatDate(value) : ""),
            sortable: true,
          },
          {
            key: "status",
            label: this.$t("orders.columns.status"),
            formatter: value => this.$t(`orders.status.${value}`),
            sortable: true,
          },
          {
            key: "totalWithDiscount",
            label: this.$t("orders.columns.total"),
            formatter: value => helper.formatPrice(value),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("orders.columns.actions"),
            thStyle: "width: 150px;",
          },
        ];
      }

      return [
        {
          key: "shop",
          label: this.$t("orders.columns.shop"),
          formatter: value => value.name,
        },
        {
          key: "itemsCount",
          label: this.$t("orders.columns.items-count"),
        },
        {
          key: "date",
          label: this.$t("orders.columns.date"),
          formatter: value => helper.formatDate(value),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("orders.columns.status"),
          formatter: value => this.$t(`orders.status.${value}`),
        },
        {
          key: "totalWithDiscount",
          label: this.$t("orders.columns.total"),
          formatter: value => helper.formatPrice(value),
        },
        {
          key: "actions",
          label: this.$t("orders.columns.actions"),
          thStyle: "width: 150px;",
        },
      ];
    },
  },
  methods: {
    orders({ currentPage, perPage, filter, sortBy, sortDesc }) {
      this.isLoading = true;

      return this.listApi(currentPage, perPage || 10, {
        filters: typeof filter === "object" ? filter : { search: filter },
        orderBy: { column: sortBy, order: sortDesc ? "DESC" : "ASC" },
      }).then(response => {
        this.isLoading = false;

        const payload = response.data;
        this.total = payload.total;

        return payload.data;
      });
    },
    async exportOrders() {
      if (this.exportOrdersLoading) {
        return;
      }

      this.exportOrdersLoading = true;

      try {
        const response = await api.exportOrders(this.liveId, {
          filters: typeof this.filter === "object" ? this.filter : { search: this.filter },
        });

        helper.download(response.data, this.$t("orders.export-name") + ".xlsx");
      } catch (error) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(error);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(error));
          Bugsnag.notify(error);
        }
      }

      this.exportOrdersLoading = false;
    },
    refreshTable() {
      this.$refs["orders-table"].refresh();
    },
    savePage(resetPage = false) {
      if (this.restoring) {
        return;
      }

      if (resetPage) {
        this.currentPage = 1;
      }

      localStorage.setItem(
        `${this.isSeller ? "seller" : "user"}_orders_page_${this.liveId || "all"}`,
        this.currentPage
      );
      localStorage.setItem(`${this.isSeller ? "seller" : "user"}_orders_limit_${this.liveId || "all"}`, this.limit);
      localStorage.setItem(`${this.isSeller ? "seller" : "user"}_orders_sortBy_${this.liveId || "all"}`, this.sortBy);
      localStorage.setItem(
        `${this.isSeller ? "seller" : "user"}_orders_sortDesc_${this.liveId || "all"}`,
        this.sortDesc ? "1" : "0"
      );
      localStorage.setItem(
        `${this.isSeller ? "seller" : "user"}_orders_filters_${this.liveId || "all"}`,
        JSON.stringify(this.filter)
      );
    },
    restorePage() {
      this.restoring = true;
      const previousFilters = localStorage.getItem(
        `${this.isSeller ? "seller" : "user"}_orders_filters_${this.liveId || "all"}`
      );
      if (previousFilters) {
        this.filter = JSON.parse(previousFilters);
      }

      const previousPage = localStorage.getItem(
        `${this.isSeller ? "seller" : "user"}_orders_page_${this.liveId || "all"}`
      );
      if (previousPage) {
        setTimeout(() => {
          this.currentPage = previousPage;
        }, 300);
      }

      const previousLimit = localStorage.getItem(
        `${this.isSeller ? "seller" : "user"}_orders_limit_${this.liveId || "all"}`
      );
      if (previousLimit) {
        this.limit = previousLimit;
      }

      const previousSortBy = localStorage.getItem(
        `${this.isSeller ? "seller" : "user"}_orders_sortBy_${this.liveId || "all"}`
      );
      if (previousSortBy) {
        this.sortBy = previousSortBy;
      }

      const previousSortDesc = localStorage.getItem(
        `${this.isSeller ? "seller" : "user"}_orders_sortDesc_${this.liveId || "all"}`
      );
      if (previousSortDesc) {
        this.sortDesc = !!previousSortDesc;
      }

      setTimeout(() => (this.restoring = false), 200);
    },
    selectAllVisibleOrder() {
      this.$refs["orders-table"].selectAllRows();
    },
    unselectAllVisibleOrder() {
      this.$refs["orders-table"].clearSelected();
    },
    orderSelected(orders) {
      this.selectedOrders = orders.map(order => order.id);
      console.log(this.selectedOrders);

      this.selectAllVisibleOrderCheckbox = this.selectedOrders.length === parseInt(this.limit);
    },
    toggleSelectedOrders(checked) {
      if (checked) {
        this.selectAllVisibleOrder();
      } else {
        this.unselectAllVisibleOrder();
      }
    },
    async exportPdfs() {
      if (this.exportOrdersPdfLoading) {
        return;
      }

      this.exportOrdersPdfLoading = true;

      try {
        const response = await api.exportOrdersPdf({
          orderIds: this.selectedOrders,
        });

        console.log(response);

        helper.download(response.data, this.$t("orders.export-name") + ".pdf");
      } catch (error) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(error);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(error));
          Bugsnag.notify(error);
        }
      }

      this.exportOrdersPdfLoading = false;
    },
    updateStatus() {
      if (!this.orderStatus) {
        return;
      }

      const promises = [];

      this.selectedOrders.forEach(orderId => {
        promises.push(
          api
            .updateOrderStatus(orderId, this.orderStatus)
            .then(() =>
              this.$bvToast.toast(this.$t("order.update-status.text"), {
                title: this.$t("order.update-status.title"),
                variant: "success",
                solid: true,
              })
            )
            .catch(e => {
              this.$bvToast.toast(this.$t("common.error.text"), {
                title: this.$t("common.error.title"),
                variant: "danger",
                solid: true,
              });

              console.log(e);
              if (process.env.NODE_ENV === "production") {
                Bugsnag.leaveBreadcrumb(JSON.stringify(e));
                Bugsnag.notify(e);
              }

              setTimeout(() => location.reload(), 2000);
            })
        );
      });

      Promise.all(promises)
        .then(() => {
          this.refreshTable();
          this.orderStatus = null;
        })
        .catch(error => {
          this.refreshTable();
          this.orderStatus = null;

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        });
    },
    getItemName(orderItem, data) {
      let name = "";

      if (data.item.live) {
        const product = data.item.live.products.find(liveProduct => liveProduct.product.id === orderItem.product.id);
        if (product) {
          name += `#${product.order}`;
        }
      }

      name += ` ${orderItem.product.name}`;

      if (orderItem.productVariant) {
        name += ` (${orderItem.productVariant.attributes.size})`;
      }

      return name;
    },
  },
  mounted() {
    this.$nextTick(() => this.restorePage());
  },
  watch: {
    currentPage() {
      this.savePage();
    },
    limit() {
      this.savePage();
    },
    sortBy() {
      this.savePage();
    },
    sortDesc() {
      this.savePage();
    },
    "filter.status"() {
      this.savePage(true);
    },
    "filter.search"() {
      this.savePage(true);
    },
  },
};
</script>

<style lang="scss" scoped>
#order-filter-status,
#order-status {
  width: 100px;
}
</style>
