export default {
  getDate(date) {
    if (typeof date === "string" || typeof date === "number") {
      date = new Date(date);
    }

    if (!this.isValidDate(date)) {
      throw new Error(`invalid date ${date} passed`);
    }

    return date;
  },
  isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
  },
  // Return date with format dd/mm/YYYY
  formatDate(rawDate) {
    const date = this.getDate(rawDate);
    const day = (date.getDate() + "").padStart(2, "0");
    const month = (date.getMonth() + 1 + "").padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },

  // Return date with format YYYY-mm-dd
  formatDateISO(rawDate) {
    const date = this.getDate(rawDate);
    const day = (date.getDate() + "").padStart(2, "0");
    const month = (date.getMonth() + 1 + "").padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  },

  // Return date with format YYYY-mm-dd
  formatDateTimeISO(rawDate) {
    return this.formatDateISO(rawDate) + "T" + this.formatTime(rawDate, true) + this.getTimezoneOffset(rawDate);
  },

  formatTime(rawDate, withSecond = false) {
    const date = this.getDate(rawDate);
    const hour = (date.getHours() + "").padStart(2, "0");
    const minute = (date.getMinutes() + "").padStart(2, "0");
    const second = (date.getSeconds() + "").padStart(2, "0");

    return `${hour}:${minute}` + (withSecond ? `:${second}` : "");
  },

  formatDateTime(rawDate, separator = " ", withSecond = false) {
    return this.formatDate(rawDate) + separator + this.formatTime(rawDate, withSecond);
  },

  isDST(date) {
    let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== date.getTimezoneOffset();
  },

  getTimezoneOffset(rawDate = null) {
    const date = rawDate ? new Date(rawDate) : new Date();
    let offset = -date.getTimezoneOffset();
    const operator = offset >= 0 ? "+" : "";

    if (this.formatDate(date) === "27/03/2022") {
      offset += 60;
    }

    return operator + `${offset / 60}`.padStart(2, "0") + ":" + `${offset % 60}`.padStart(2, "0");
  },
  formatPrice(price) {
    return `${(price.amount / 100).toFixed(2)} €`;
  },
  buildShareUrl(live, locale = "fr", shareToken = null) {
    return `${process.env.VUE_APP_SHARE_URL}/${locale}/${live.id}` + (shareToken ? `/${shareToken}` : "");
  },
  download(blob, filename = null, blobOptions = null) {
    const url = window.URL.createObjectURL(new Blob([blob], blobOptions));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename || "file"); //or any other extension
    document.body.appendChild(link);
    link.click();
  },
  limitChars(word, limit) {
    if (word.length > limit) {
      return `${word.substr(0, limit)}...`;
    }
    return word;
  },
  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  },
  rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  },
  getColorForBackground(bgColor) {
    const rgb = this.hexToRgb(bgColor);
    return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? "black" : "white";
  },
};
